export const ABOUT_PAGE = "/about/";

// How does it work?
export const LOADING_DATA = "/how-does-it-work/loading-data/";
export const QUERY_DATA = "/how-does-it-work/query-data/";
export const REPORT_DATA = "/how-does-it-work/reporting/";

// Solutions
export const DATA_WAREHOUSE_MANAGEMENT =
	"/solutions/date-warehouse-management/";

export const DATA_SERVICE = "/data-service/";
export const USE_CASES = "/use-cases/";
export const DATA_SOURCES = "/data-sources/";
export const PRICING = "/pricing/";
export const RESOURCES = "/resources/";
export const RESOURCE_LIBRARY = "/resource-library/";
export const UA_MIGRATION = "/ua-migration/";
export const RANK_TRACKING = "/rank-tracking-service/";
export const REPORTS = "/reports/";

// Reports
export const TRAFFIC_CHANGE_REPORT = "/reports/traffic-change/";
export const BRANDED_SERP_REPORT = "/reports/branded-serp/";
export const CRUX_REPORT = "/reports/branded-serp/";
export const FEATURED_SNIPPET_REPORT = "/reports/featured-snippet/";
export const SEGMENT_TRACKER_REPORT = "/reports/segment-tracker/";
export const TEMPLATE_CHECKER_REPORT = "/reports/template-checker/";
export const RANK_TRACKER_REPORT = "/reports/rank-tracker/";
export const LOG_MONITOR_REPORT = "/reports/log-monitor/";

// Tools
export const TOOL_DOWNLOAD_SEARCH_CONSOLE =
	"/resources/tools/download-search-console/";
export const TOOL_FINDING_CANNIBALISATION =
	"/resources/tools/finding-cannibalisation/";

// Use cases
export const AGENCIES = "/use-cases/agency/";
export const IN_HOUSE_TEAMS = "/use-cases/in-house/";

// export const LOADING
export const CONTACT_ME_PAGE = "/initial-meeting/";
export const CONTACT_ME_PAGE_OLD = "/contact/";
export const LEARN_PAGE = "/learn/";

export const userJourneyMapping = {
	search_console: "/topic/search-console/",
	technical: "/topic/technical/",
	ga4: "/topic/ga4/"
};

export const allPosts = "/all/";
