import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

// Get location

// Local imports
import {
	DATA_SERVICE,
	CONTACT_ME_PAGE,
	DATA_SOURCES,
	PRICING,
	RESOURCES,
	UA_MIGRATION,
	IN_HOUSE_TEAMS,
	AGENCIES,
	RANK_TRACKING,
	REPORTS
} from "src/routesConstants";

import logoImg from "static/images/logos/logo_colour.png";

import styles from "./Header.module.scss";

class Header extends React.Component {
	static propTypes = {
		isWhite: PropTypes.bool
	};

	static defaultProps = {
		isWhite: false
	};

	constructor(props) {
		super(props);
		this.state = {
			toggleMenu: false
		};
	}

	toggleMenu = () => {
		this.setState(prevState => ({
			toggleMenu: !prevState.toggleMenu
		}));
	};

	render() {
		const isMenuActive = this.state.toggleMenu ? "is-active" : "";
		const isWhite = this.props.isWhite ? "" : "primaryBar";
		const primaryCTAColour = this.props.isWhite
			? "buttonSecondary"
			: "is-link is-inverted";
		// Set logo colour
		// let logoFluid = this.props.data.logoWhite.childImageSharp.fluid;
		// if (this.props.isWhite) {
		// 	logoFluid = this.props.data.logoGreen.childImageSharp.fluid;
		// }

		return (
			<div className={`${styles.outerPipeNav}`}>
				<div className={`${styles.innerPipeNav}`}>
					<nav
						className={`navbar ${styles.navbarExtra} ${isWhite}`}
						role="navigation"
						aria-label="main navigation"
					>
						<div className={`navbar-brand ${isMenuActive}`}>
							<Link to="/" className="m-r-lg m-t-xs">
								<div>
									<img
										className={`${styles.overrideFluidSize}`}
										src={logoImg}
										alt="Piped Out Logo"
										// width="224"
										// height="53"
									></img>
								</div>
							</Link>
							<a
								role="button"
								className="navbar-burger"
								aria-label="menu"
								aria-expanded="false"
								onClick={this.toggleMenu}
								tabIndex={0}
							>
								<span aria-hidden="true"></span>
								<span aria-hidden="true"></span>
								<span aria-hidden="true"></span>
							</a>
						</div>
						<div className={`navbar-menu ${isMenuActive}`}>
							{/* <div className="navbar-item has-dropdown is-hoverable">
								<a className="navbar-link">How does it work?</a>
								<div className="navbar-dropdown">
									<Link
										className="navbar-item"
										to={LOADING_DATA}
										activeClassName={styles.activeNav}
									>
										Load
									</Link>
									<Link
										className="navbar-item"
										to={REPORT_DATA}
										activeClassName={styles.activeNav}
									>
										Report
									</Link>
									<Link
										className="navbar-item"
										to={QUERY_DATA}
										activeClassName={styles.activeNav}
									>
										Analyse
									</Link>
								</div>
							</div> */}
							<div className="navbar-item has-dropdown is-hoverable">
								<a className="navbar-link">Services</a>
								<div className="navbar-dropdown">
									<Link
										className="navbar-item"
										to={DATA_SERVICE}
										activeClassName={styles.activeNav}
									>
										Full Data Service
									</Link>
									<Link
										className="navbar-item"
										to={RANK_TRACKING}
										activeClassName={styles.activeNav}
									>
										Rank Tracking
									</Link>
								</div>
							</div>
							<div className="navbar-item has-dropdown is-hoverable">
								<a className="navbar-link">Use Cases</a>
								<div className="navbar-dropdown">
									<Link
										className="navbar-item"
										to={IN_HOUSE_TEAMS}
										activeClassName={styles.activeNav}
									>
										In House Teams
									</Link>
									<Link
										className="navbar-item"
										to={AGENCIES}
										activeClassName={styles.activeNav}
									>
										Agencies
									</Link>
								</div>
							</div>
							<Link
								className="navbar-item"
								to={REPORTS}
								activeClassName={styles.activeNav}
							>
								Reports
							</Link>
							{/* <Link
								className="navbar-item"
								to={DATA_SOURCES}
								activeClassName={styles.activeNav}
							>
								Data sources
							</Link> */}
							<Link
								className="navbar-item"
								to={PRICING}
								activeClassName={styles.activeNav}
							>
								Pricing
							</Link>
							<Link
								className="navbar-item"
								to={RESOURCES}
								activeClassName={styles.activeNav}
							>
								Resources
							</Link>
							<div className="navbar-end">
								<a
									className={`button ${primaryCTAColour} navbar-item`}
									href={CONTACT_ME_PAGE}
								>
									Book an intro call
								</a>

								<div className="navbar-start"></div>
							</div>
						</div>
					</nav>
				</div>
			</div>
		);
	}
}
export default Header;

// export default ({ isWhite }) => (
// 	<StaticQuery
// 		query={graphql`
// 			query {
// 				logoWhite: file(
// 					relativePath: { eq: "images/logos/logo_white_new.png" }
// 				) {
// 					childImageSharp {
// 						fluid(maxWidth: 239, maxHeight: 53) {
// 							...GatsbyImageSharpFluid
// 						}
// 					}
// 				}

// 				logoGreen: file(
// 					relativePath: { eq: "images/logos/logo_colour.png" }
// 				) {
// 					childImageSharp {
// 						fluid(maxWidth: 239, maxHeight: 53) {
// 							...GatsbyImageSharpFluid
// 						}
// 					}
// 				}
// 			}
// 		`}
// 		render={data => <Header data={data} isWhite={isWhite} />}
// 	/>
// );

// eslint-disable-next-line react/prefer-stateless-function
// class Header extends React.Component {
// 	render() {
// 		console.log(this.props);

// 		return (

// 		);
// 	}
// }

// export const query = graphql`
// 	query {
// 		file(relativePath: { regex: "/a/" }) {
// 			id
// 		}
// 	}
// `;

// export default Header;
