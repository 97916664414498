import React from "react";
import Helmet from "react-helmet";
import Footer from "src/components/generic/Footer/Footer";
import Header from "src/components/generic/Header/Header";
import config from "data/SiteConfig";

// Layout needs columns
import "normalize.css";
// import "src/styles/layout.scss";
import "src/styles/main.scss";

const MainLayoutWhite = ({ children }) => (
	<React.Fragment>
		<Header isWhite={true} />
		<Helmet>
			<meta name="description" content={config.siteDescription} />
		</Helmet>
		{children}
		<Footer />
	</React.Fragment>
);

export default MainLayoutWhite;
