import React from "react";
import ContactMeForm from "src/components/generic/ContactMeForm/ContactMeForm";
import { CONTACT_ME_PAGE } from "src/routesConstants";

// Local imports
import config from "data/SiteConfig";
import styles from "./Footer.module.scss";

function Footer() {
	return (
		<footer className="p-t-xl p-b-xl primaryBar">
			<div className="container">
				<div className="columns">
					<div className="column">
						<p>
							If you&apos;d like a demo/quote, please contact us!
						</p>
						<a
							className={`button is-link is-inverted`}
							href={CONTACT_ME_PAGE}
						>
							Book a demo
						</a>
					</div>
				</div>
			</div>
		</footer>
	);
}

export default Footer;
