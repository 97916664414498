/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */

import React from "react";
import PropTypes from "prop-types";
import { navigate } from "gatsby";

import { FaUser } from "@react-icons/all-files/fa/FaUser";
import { FaBuilding } from "@react-icons/all-files/fa/FaBuilding";
import { FaWindowMaximize } from "@react-icons/all-files/fa/FaWindowMaximize";
import { FaEnvelope } from "@react-icons/all-files/fa/FaEnvelope";

function encode(data) {
	return Object.keys(data)
		.map(
			key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
		)
		.join("&");
}

const propTypes = {
	formName: PropTypes.string.isRequired
};

class ContactMeForm extends React.Component {
	constructor(props) {
		// Required step: always call the parent class' constructor
		super(props);

		this.ContactMeForm = React.createRef();

		// Add in handles
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);

		// Set the state directly. Use props if necessary.
		this.state = {};
	}

	handleChange = e => {
		this.setState({ [e.target.name]: e.target.value });
	};

	handleSubmit = e => {
		e.preventDefault();
		const form = e.target;

		fetch("/", {
			method: "POST",
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			body: encode({
				"form-name": form.getAttribute("name"),
				...this.state
			})
		})
			.then(() => navigate(form.getAttribute("action")))
			.catch(error => console.log(error));
	};

	render() {
		return (
			<form
				name={this.props.formName}
				method="POST"
				action="/contact/thanks"
				data-netlify="true"
				onSubmit={this.handleSubmit}
				id="contact-form"
			>
				<input
					type="hidden"
					name="form-name"
					value={this.props.formName}
				/>
				<div className="field">
					<label className="label" htmlFor="firstName">
						Name
					</label>
					<div className="control has-icons-left">
						<span className="icon is-small icon-fixer">
							<FaUser />
						</span>
						<input
							name="name"
							id="name"
							className="input"
							type="text"
							placeholder="John Lester"
							onChange={this.handleChange}
							required
						/>
					</div>
				</div>
				<div className="field">
					<label className="label" htmlFor="company">
						Company
					</label>
					<div className="control has-icons-left">
						<span className="icon is-small icon-fixer">
							<FaBuilding />
						</span>
						<input
							name="form-company"
							id="company"
							className="input"
							type="text"
							placeholder="My Company, Ltd."
							onChange={this.handleChange}
							required
						/>
					</div>
				</div>
				<div className="field">
					<label className="label" htmlFor="website">
						Website
					</label>
					<div className="control has-icons-left">
						<span className="icon is-small icon-fixer">
							<FaWindowMaximize />
						</span>
						<input
							name="form-website"
							id="website"
							className="input"
							type="text"
							placeholder="www.example.com"
							onChange={this.handleChange}
							required
						/>
					</div>
				</div>
				<div className="field">
					<label className="label" htmlFor="email">
						Email
					</label>
					<div className="control has-icons-left">
						<span className="icon is-small icon-fixer">
							<FaEnvelope />
						</span>
						<input
							name="email"
							id="email"
							className="input"
							type="email"
							placeholder="you@example.com"
							onChange={this.handleChange}
							required
						/>
					</div>
				</div>
				<div className="field p-t-m">
					<div className="control">
						<button
							type="submit"
							className="button is-primary is-link"
						>
							Submit
						</button>
					</div>
				</div>
			</form>
		);
	}
}

ContactMeForm.propTypes = propTypes;

export default ContactMeForm;
